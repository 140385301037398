<template>
  <el-drawer
    title="Charge customer"
    :visible.sync="setShow"
    @close="closeEvent"
    append-to-body
    width="30%"
  >
    <div class="el-drawer--content">
      <div class="el-drawer--content__body">
        <el-form
          :model="form"
          label-position="top"
          ref="form"
          v-loading="fetching"
        >
          <el-row>
            <el-col>
              <el-form-item
                label="Payment type"
                prop="payment_type"
                :rules="validateField()"
              >
                <el-radio-group
                  v-model="form.payment_type"
                  class="eden-custom--radio flex"
                >
                  <el-radio
                    v-for="(type, i) in paymentTypes"
                    :key="i"
                    :label="type.toLowerCase()"
                    :value="type.toLowerCase()"
                    >{{ formatName(type) }}</el-radio
                  >
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col>
              <el-form-item
                label="Payment method"
                prop="payment_channel"
                :rules="validateField()"
              >
                <el-radio-group
                  v-model="form.payment_channel"
                  class="eden-custom--radio flex"
                >
                  <el-radio
                    v-for="(paymentChannel, i) in paymentChannels"
                    :key="i"
                    :label="paymentChannel.toLowerCase()"
                    :value="paymentChannel.toLowerCase()"
                    >{{ formatName(paymentChannel) }}</el-radio
                  >
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row v-if="form.payment_channel === 'card'">
            <el-col :span="24">
              <el-form-item
                label="Select billing card"
                prop="key"
                :rules="validateField()"
              >
                <el-select
                  v-model="form.key"
                  placeholder="Choose billing card"
                  @change="setGatewayChannel"
                >
                  <el-option
                    v-for="(value, key, index) in cards"
                    :key="index"
                    :label="`${formatDebitCard(value.bin, value.last4)}`"
                    :value="key"
                    :disabled="value.is_deleted"
                  >
                    <div class="is-flex is-justify-between is-align-center">
                      <img
                        :style="{
                          height: '18px',
                          opacity: value.is_deleted ? '0.4' : '1',
                        }"
                        :src="getCardImage(value.brand, 'svg')"
                        :alt="`${formatText(value.brand)}`"
                      />
                      <span>
                        {{ formatDebitCard(value.bin, value.last4) }}</span
                      >
                      <span> {{ value.exp_month }} / {{ value.exp_year }}</span>
                      <el-tag v-if="value.is_deleted" type="danger" size="mini"
                        >DFG</el-tag
                      >
                    </div>
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col>
              <el-form-item
                label="Services"
                prop="service"
                :rules="validateField()"
              >
                <el-select
                  v-model="form.service"
                  multiple
                  placeholder="select services"
                  :value-key="'id'"
                >
                  <el-option
                    v-for="(service, index) in services"
                    :key="index"
                    :label="formatName(service.service_name)"
                    :value="service.service_name.toLowerCase()"
                  >
                    {{ service.service_name }}
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>

          <payment-breakdown
            :payment-type="form.payment_type"
            :services="form.service"
            :breakdown.sync="form.break_down"
            :amount.sync="form.amount"
          />
          <el-row type="flex" class="mt-3">
            <el-col :span="24">
              <el-form-item
                label="Amount to pay"
                prop="amount"
                :rules="validateField()"
              >
                <el-input
                  v-model.number="form.amount"
                  placeholder="Enter amount"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </div>
    <div class="el-drawer--footer is-align-center is-justify-between">
      <template>
        <el-button plain @click="closeEvent">Cancel</el-button>
        <el-button type="primary" :loading="charging" @click="charge"
          >Charge Customer</el-button
        >
      </template>
    </div>
  </el-drawer>
</template>

<script>
import customerCards from "@/requests/customers/customer/cards";
import customer from "@/requests/customers/customer/actions";
import PaymentBreakdown from "@/components/Subscriptions/Transactions/Breakdown/PaymentBreakdown";
import wallet from "@/requests/customers/customer/wallet";

export default {
  name: "CustomerServicesCharge",
  components: {
    PaymentBreakdown,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    channel: {
      type: String,
      default: "card",
    },
    customer: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      form: {
        email: "",
        key: "",
        authorization_code: "",
        payment_type: "subscription",
        payment_channel: this.channel,
        service: [],
        gateway_channel: "",
        amount: "",
        break_down: [],
      },
      fetching: false,
      charging: false,
      cards: {},
      paymentChannels: ["wallet", "card"],
      paymentTypes: ["subscription", "one-off"],
    };
  },
  computed: {
    setShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
    services() {
      return this.$store.getters.services;
    },
  },
  watch: {
    show() {
      if (this.show) {
        this.form.email = this.customer.email;
        this.getBillingCards();
        let plan = this.customer.next_billing_date[0].plan;
        this.setServicesBreakDown(plan);
      }
    },
  },
  methods: {
    closeEvent() {
      this.setShow = false;
      this.$refs.form.resetFields();
    },
    setServicesBreakDown(plan) {
      let total = 0;
      this.form.service = Object.keys(plan);
      this.form.break_down = this.form.service.map((service) => {
        total += plan[service].amount ? plan[service].amount : 0;
        const config = plan[service];
        return {
          service: {
            name: service,
          },
          amount: config.amount || null,
          item: config.item,
          frequency: config.frequency || null,
          service_day: config.service_day,
          home_type: config.home_type,
          quantity: config.qty,
        };
      });
      this.form.amount = total;
    },
    getBillingCards() {
      this.fetching = true;
      customerCards
        .index(this.form.email)
        .then((response) => {
          const { status, data: cards = [] } = response.data;
          if (status) {
            cards.forEach((card) => {
              const key = `${card.bin}${card.last4}`;
              if (!this.cards[key]) {
                this.cards[key] = { ...card };
              }
            });
            this.fetching = false;
          } else {
            this.$message.error("No cards saved.");
            this.fetching = false;
          }
        })
        .catch((error) => {
          this.$message.error(
            `${error.response.data.message}, try another email`,
          );
          this.fetching = false;
        });
    },
    setGatewayChannel(key) {
      const { authorization_code, gateway_channel } = this.cards[key];
      this.form.authorization_code = authorization_code;
      this.form.gateway_channel = gateway_channel;
    },
    charge() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return false;
        }
        const breakdown = this.form.break_down.map((item) => {
          return {
            ...item,
            frequency:
              this.form.payment_type === "one-off" ? "one-off" : item.frequency,
            service: item.service.name,
          };
        });
        const payload = {
          ...this.parseData(this.form),
          service: this.form.service.toString(),
        };
        payload.break_down = breakdown;
        delete payload.key;

        this.charging = true;
        if (this.form.payment_channel === "card") {
          customer
            .charge(payload)
            .then((response) => {
              if (response.data.status) {
                this.$message.success(response.data.message);
                this.charging = false;
                this.$emit("success");
                this.closeEvent();
              } else {
                this.charging = false;
                this.$message.error(response.data.message);
              }
            })
            .catch((error) => {
              this.charging = false;
              this.$message.error(error.response.data.message);
            });
        } else if (this.form.payment_channel === "wallet") {
          wallet
            .charge(
              this.parseData({
                payment_type: payload.payment_type,
                amount: payload.amount,
                break_down: payload.break_down,
              }),
              this.customer.id,
            )
            .then((response) => {
              if (response.data.status) {
                this.$message.success(response.data.message);
                this.charging = false;
                this.$emit("success");
                this.closeEvent();
              } else {
                this.charging = false;
                this.$message.error(response.data.message);
              }
            })
            .catch((error) => {
              this.charging = false;
              this.$message.error(error.response.data.message);
            });
        }
        return true;
      });
    },
  },
};
</script>

<style lang="scss">
.eden-service-charge {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .el-checkbox {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;

    .el-checkbox__label {
      padding-left: 0;
    }

    .el-checkbox__input {
      padding-left: 10px !important;
      top: 2px;
    }
  }
}
</style>
