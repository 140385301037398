<template>
  <div>
    <div
      v-for="(service, index) in setBreakdown"
      :key="index"
      :class="`${service.service.name} service-configuration`"
    >
      <p class="text-bold font-sm mb-10">
        {{ formatText(service.service.name) }}
      </p>
      <!-- Service Type -->
      <template v-if="service.service.name !== 'meal'">
        <el-row v-loading="serviceTypes.loading">
          <el-col :span="24">
            <el-form-item
              label="Select service type"
              :prop="'break_down.' + index + '.item'"
              :rules="validateField()"
            >
              <el-radio-group
                v-model="service.item"
                class="eden-custom--radio flex"
              >
                <el-radio
                  v-for="(type, index) in serviceTypes[service.service.name]"
                  :key="index"
                  :label="type.slug"
                  >{{ type.name }}</el-radio
                >
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
      </template>

      <!-- Frequency -->
      <template v-if="paymentType === 'subscription'">
        <el-row>
          <el-col>
            <el-form-item
              :label="`${serviceLabels[service.service.name]}`"
              :prop="'break_down.' + index + '.frequency'"
              :rules="validateField()"
            >
              <el-radio-group
                v-model="service.frequency"
                class="eden-custom--radio flex"
              >
                <el-radio
                  v-for="(frequency, index) in serviceFrequencies[
                    service.service.name
                  ]"
                  :key="index"
                  :label="index"
                  >{{ frequency }}</el-radio
                >
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
      </template>

      <!-- Service Day -->
      <template v-if="paymentType === 'subscription'">
        <el-form-item
          label="Select service day"
          :prop="'break_down.' + index + '.service_day'"
          :rules="validateField()"
        >
          <el-checkbox-group
            v-model="service.service_day"
            class="eden-custom--checkbox flex"
            :min="1"
          >
            <el-checkbox
              v-for="(value, key, index) in serviceDays()"
              :key="index"
              :label="key"
              >{{ value }}</el-checkbox
            >
          </el-checkbox-group>
        </el-form-item>
      </template>
      <template v-else-if="paymentType.toLowerCase() !== 'subscription'">
        <el-row type="flex">
          <el-col :span="24">
            <el-form-item
              label="Select date(s)"
              :prop="'break_down.' + index + '.service_day'"
              :rules="validateField()"
            >
              <el-date-picker
                v-model="service.service_day"
                value-format="yyyy-MM-dd"
                type="dates"
                format="dd MMM, yyyy"
                popper-class="customized-multi-day-picker"
                prefix-icon="eden-icon-calendar"
              />
            </el-form-item>
          </el-col> </el-row
      ></template>

      <!-- Home Type -->
      <template v-if="service.service.name === 'cleaning'">
        <el-row>
          <el-col>
            <el-form-item
              label="Select home type"
              :prop="'break_down.' + index + '.home_type'"
              :rules="validateField()"
            >
              <el-radio-group
                v-model="service.home_type"
                class="eden-custom--radio flex"
              >
                <el-radio
                  v-for="(type, i) in homeTypes"
                  :key="i"
                  :label="type.toLowerCase()"
                  >{{ type }}</el-radio
                >
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
      </template>

      <!-- Quantity -->
      <template v-if="paymentType === 'subscription'">
        <el-row>
          <el-col>
            <el-form-item :label="labels[service.service.name.toLowerCase()]">
              <div class="counter">
                <el-button
                  :type="'control'"
                  icon="el-icon-minus"
                  :disabled="service.quantity === 1"
                  @click="updateServiceNumber('subtract', index)"
                />
                <el-input
                  v-model.number="service.quantity"
                  v-number
                  type="text"
                />
                <el-button
                  :type="'control'"
                  icon="el-icon-plus"
                  @click="updateServiceNumber('add', index)"
                />
              </div>
            </el-form-item>
          </el-col>
        </el-row>
      </template>
      <template v-else>
        <el-row>
          <el-col>
            <el-form-item
              label="Description"
              :prop="`break_down.${index}.description`"
            >
              <el-input
                v-model="service.description"
                :rows="5"
                type="textarea"
                placeholder="Give a brief description of the transaction"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </template>

      <!-- Amount -->
      <el-row>
        <el-col :span="24">
          <el-form-item
            label="Amount"
            :prop="'break_down.' + index + '.amount'"
            :rules="validateField()"
          >
            <el-input
              v-model.number="service.amount"
              v-number
              @input="updateAmount"
            />
          </el-form-item>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import * as actions from "@/store/action-types";

export default {
  name: "PaymentBreakdown",
  props: {
    paymentType: {
      type: String,
      default: "",
    },
    services: {
      type: Array,
      default() {
        return [];
      },
    },
    breakdown: {
      type: Array,
      default() {
        return [];
      },
    },
    amount: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      serviceLabels: {
        beauty: "Service frequency",
        cleaning: "Service frequency",
        laundry: "Pickup frequency",
        meal: "Delivery frequency",
      },
      serviceFrequencies: {
        meal: {
          "daily": "Daily",
          "weekly": "Weekly",
          "bi-weekly": "Every 2 Weeks",
        },
        laundry: {
          "weekly": "Weekly",
          "bi-weekly": "Every 2 Weeks",
          "monthly": "Monthly",
        },
        cleaning: {
          "weekly": "Weekly",
          "bi-weekly": "Every 2 Weeks",
          "monthly": "Monthly",
        },
        beauty: {
          "weekly": "Weekly",
          "bi-weekly": "Every 2 Weeks",
          "monthly": "Monthly",
        },
      },
      serviceTypes: {
        loading: false,
        beauty: [],
        cleaning: [],
        laundry: [],
      },
      labels: {
        beauty: "Total number of services in a month",
        cleaning: "Total number of areas",
        laundry: "Number of bags",
        meal: "Total number of meals per week",
      },
    };
  },
  computed: {
    setBreakdown: {
      get() {
        return this.breakdown;
      },
      set(value) {
        this.$emit("update:breakdown", value);
      },
    },
    setAmount: {
      get() {
        return this.amount;
      },
      set(value) {
        this.$emit("update:amount", value);
      },
    },
    homeTypes() {
      const location = this.$store.getters.location;
      if (location === "KE") {
        return ["Apartment", "House"];
      } else {
        return ["Flat", "Duplex"];
      }
    },
  },
  watch: {
    services() {
      this.sortServices();
    },
    paymentType() {
      let breakdown;
      breakdown = this.setBreakdown.map((service) => {
        service.service_day = [];
        return service;
      });
      this.setBreakdown = breakdown;
    },
  },
  created() {
    this.services.forEach((service) => {
      this.getServiceTypes(service);
    });
  },
  methods: {
    getServiceTypes(service) {
      this.serviceTypes.loading = true;
      switch (service) {
        case "beauty":
          if (this.serviceTypes.beauty.length) {
            this.serviceTypes.loading = false;
            break;
          }
          this.$store.dispatch(actions.GET_SERVICE_TYPES, "beauty").then(() => {
            this.setServiceTypes("beauty");
          });
          break;
        case "cleaning":
          if (this.serviceTypes.cleaning.length) {
            this.serviceTypes.loading = false;
            break;
          }
          this.$store
            .dispatch(actions.GET_SERVICE_TYPES, "cleaning")
            .then(() => {
              this.setServiceTypes("cleaning");
            });
          break;
        case "laundry":
          if (this.serviceTypes.laundry.length) {
            this.serviceTypes.loading = false;
            break;
          }
          this.$store
            .dispatch(actions.GET_SERVICE_TYPES, "laundry")
            .then(() => {
              this.setServiceTypes("laundry");
            });
          break;
        default:
          this.serviceTypes.loading = false;
          break;
      }
    },
    setServiceTypes(service) {
      this.serviceTypes[service] = this.$store.getters.service_types[service];
      this.serviceTypes.loading = false;
    },
    sortServices() {
      const added = this.services.filter(
        (service) =>
          !this.setBreakdown.map((item) => item.service.name).includes(service),
      );

      if (added.length) {
        let serviceName = added[0].toLowerCase();
        this.setBreakdown.push({
          service: {
            name: serviceName,
          },
          amount: null,
          item: serviceName === "meal" ? null : "",
          frequency: "",
          service_day: [],
          home_type: "",
          quantity: 1,
          description: "",
        });
        this.getServiceTypes(added[0]);
        return;
      }

      const removed = this.setBreakdown.filter(
        (service) => !this.services.includes(service.service.name),
      );
      if (removed.length) {
        this.setBreakdown = this.setBreakdown.filter(
          (item) => item.service.name !== removed[0].service.name,
        );
        setTimeout(() => {
          this.updateAmount();
        }, 100);
      }

      if (!added.length && !removed.length) {
        this.services.forEach((service) => {
          this.getServiceTypes(service);
        });
      }
    },
    updateAmount() {
      let total = 0;
      if (this.setBreakdown.length) {
        this.setBreakdown.map((service) => {
          total += Number(service.amount);
        });
      }
      this.setAmount = total;
    },
    updateServiceNumber(action, index) {
      if (action === "add") {
        this.setBreakdown[index].quantity += 1;
      } else {
        this.setBreakdown[index].quantity -= 1;
      }
    },
  },
};
</script>

<style></style>
