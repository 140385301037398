import axios from "axios";

export default {
  index(id) {
    return axios.get(`customers/${id}/wallet/activities`);
  },

  filter(id, params = "") {
    return axios.get(`customers/${id}/wallet/activities?${params}`);
  },

  fundWallet(payload) {
    return axios.post(`/crm/individual_wallet/fund`, payload);
  },

  fundWithLink(payload) {
    return axios.post(`/payment/links/individual`, payload);
  },

  /**
   * Requests for Payment Actions
   * @param payload
   * @returns {Promise<AxiosResponse<any>>}
   */
  charge(payload, id) {
    return axios.post(`customers/${id}/charge_wallet`, payload);
  },

  redeemableServices(id) {
    return axios.get(`customers/${id}/wallet`);
  },
};
